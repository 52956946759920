.goal-content h1 {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 1.4;
}
.goal-content p {
    font-weight: 600;
    line-height: 27px;
}

.goal-image img{
    width: 100%;
    height: 100%;
}

.goal-person-img img {
    height: 360px;
    width: 300px;
    object-fit: contain;
    transform: translate(-30px, -218px);
}

.goal-content span {
    width: 50px;
    height: 50px;
    display: inline-block;
    background: #4628BA;
    text-align: center;
    line-height: 46px;
    color: #FFF;
    font-size: 22px;
    border-radius: 50%;
    margin-top: 25px;
    border: 1px solid #4628BA;
    transition: .3s ease-in-out;
    
}

.portfolio {
    display: inline-block;
    cursor: pointer;
    transition: .3s ease-in-out;


}

.portfolio button {
    margin-left: 10px;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 17px;
    color: #4628BA;
    &:hover{
        transition: .3s ease-in-out;
        text-decoration: underline;
    }
}

.goals{
    margin-top: 130px;
}
.services-item {
    text-align: center;
    color: #FFF;
    padding: 10px;
}
.services-item h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 20px 0;
    letter-spacing: 2px;
}

.services-item h1 {
    font-size: 34px;
    font-weight: 700;
    margin: 20px 0;
    letter-spacing: 2px;
}

.services-item svg {
    font-size: 50px;
}

.services-item.border-item {
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding: 0 24px;
}

.title-text button {
    padding: 14px 31px;
    background: #4628BA;
    color: #FFF;
    font-weight: 600;
    border: 2px solid #4628BA;
    border-radius: 37px;
    margin-top: 20px;
}

// .goals{background: url(../../image/illustration26.png)no-repeat ;
//     height: 100vh;
//     background-size: contain;
//     background-position: right;}
.goal-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.goals {
    margin-top: 100px !important;
}