.GraphicsBanner{
    background: url(../../image/banner-1.png)no-repeat ;
    background-size: contain;
    background-position: right;
    height: 100vh;
}

.graphics-png {
    margin-top: 280px;
}

.graphics-png img{
    width: 100%;
    height: 100%;
}