
.why-choose-us {
    background: url(../../image/image_processing20200121-30622-g2g2.aa886e61.gif) no-repeat;
    height: 100vh;
   background-size: contain;   
}

.Why_Choose_Us h1 {
    font-size: 39px;
    font-weight: 700;
    padding: 10px 0;
    line-height: 56px;
    margin-top: 30px;
}

.Why_Choose_Us p {
    line-height: 27px;
    font-size: 17px;
    color: #555555;
    font-weight: 500;
}

.Why_Choose_Us button {
    text-decoration: none;
    margin-right: 30px;
    background: #5a49f8 !important;
    padding: 12px 39px;
    color: white;
    font-weight: 700;
    border-radius: 9px;
    border: 2px solid #5a49f8 !important;
    text-transform: uppercase;
    font-size: 15px;
    &:hover{
        background: none !important;
        color: #5a49f8;
        transition: .3s ease-in;
    }
}

.new-bg{
    background: url(../../image/apps3.png) no-repeat;
    background-size: contain;
    background-position: right;
    }

