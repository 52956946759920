.web_banner {
    background: url(../../image/28.png) no-repeat;
    background-size: contain;
    background-position: right;
    height: 100vh;
}

.web-nvabr {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.web-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 30px;
}

.web-image img {
    position: relative;
    top: 160px !important;
}

.content-show-for-web{
    display: none;
}

.image-for-contact- img {
    margin-top: 250px;
    position: relative;
    margin-left: 200px;
}