.homeBanner {
    background: url(../../image/element58.png) no-repeat;
    background-position: 100%;
    background-size: inherit;
    padding: 150px 30px;
}

.topbar .wrapper .right .hamburger span {
    width: 100%;
    height: 2px;
    background-color: #4ea5ff;
    transform-origin: left;
    transition: all 2s ease;
}

.navbar ul li a {
    display: inline-block;
    margin-right: 28px;
    color: #000000;
    text-decoration: none;
    font-weight: 600;
    transition: 0.3s ease-in-out;
}
.navbar ul li a:hover{
    color: #ffffffc9;
}
.navbar ul li a.active{
    color: none !important;
}
.left a img {
    border-radius: 10px;
    width: 132px;
    height: 51px;
    object-fit: cover;
}
.home-banner-text {
    text-align: left;
}
.home-banner-text h4 {
    font-size: 17px;
    font-weight: 600;
}
.home-banner-text h1 {
    font-size: 60px;
    font-weight: 700;
    padding: 30px 0;
}
span.Typewriter__wrapper {
    color: #5a49f8 !important;
}
span.Typewriter__cursor {
    color: #5a49f8;
}
.home-banner-text p {
    font-weight: 500;
    line-height: 31px;
    font-size: 18px;
}
.banner-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: img 3s ease-in infinite alternate;
}


@keyframes img{

    0%, 50%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(-20px);     
   
    }
    
    }
.call-metacom {
    display: flex;
    margin-top: 40px;
    align-items: center;
}

.call-metacom a {
    text-decoration: none;
    margin-right: 30px;
    background: #5a49f8 !important;
    padding: 15px 35px;
    color: #FFFF;
    font-weight: 500;
    border-radius: 38px;
    border: 2px solid #5a49f8 !important;
}

.call-metacom span {
    font-weight: 500;
}
.meta-text-services {
    text-align: center;
    padding: 100px 0;
}

.content h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}
.content h1 span {
    color: #FFF !important;
}

.link {
    display: flex;
    align-items: center;
    margin-top: 5px;

}

.link span {
    font-weight: 600;
    margin-left: 20px;
    display: inline-block;
    cursor: pointer;
}

.phone a {
    background: none;
    padding: 15px 38px;
    color: #1371ff;
    text-decoration: none;
    font-weight: 600;
    border-radius: 50px;
    border: 2px solid #1371ff;
    &:hover{
        background: #2f17ff !important;
        border: 2px solid #5a49f8;
        transition: .3s ease-in-out;
        color: #FFF !important;
    }
}
.navbar ul li a {
    display: inline-block;
    margin-right: 28px;
    color: #000000;
    text-decoration: none;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    &:hover{
        color: #2f17ff;
    }
}

a.about______________________ {
    background: none !important;
    color: #5A49F8;
    border: none !important;
}