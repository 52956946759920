@import "../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');

.left a img {
  object-fit: contain !important;
  border-radius: 10px;
  width: 132px;
  height: 80px;
}
.topbar {
  width: 100%;
  height: 70px;
  background-color: #f5deb300;
  color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;


  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .topbar .wrapper .left .logo {
        font-size: 21px;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        font-weight: 300 !important;
        font-family: 'Montserrat';
        position: relative;
        top: 8px;
        font-style: italic;
        text-transform: uppercase;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;

        @include mobile{
          display: none;
        }

        .icon {
          font-size: 18px;
          margin-right: 5px;
        }

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        top: 0px;

        span {
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.change-background {
  background: white;
}
.menu ul li a{
  transition: .3s ease-in-out;
  color: black !important;
}
.menu ul li a:hover{
  color: #15023A !important;
}

.navbar ul {
  list-style: none;
  margin: 0;
  display: flex;
}

.navbar ul li a {
  display: inline-block;
  margin-right: 28px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
}

.right {
  display: none;
}
.phone a svg {
  margin-right: 2px;
}
.phone a {
  background: #5a49f8;
  padding: 15px 38px;
  color: #FFF;
  text-decoration: none;
  font-weight: 600;
  border-radius: 50px;
  transition: .3s ease-in-out;
  border:2px solid #5a49f8
}
.phone a:hover{
  background: none !important;
  color: #5a49f8 !important;
}

.navbar.custom-static-menu ul li a {
  color: #FFF ;
}

/* //drop down menu work/ */
/*  DropDown Menu CSS  */
.navbar ul li ul {
	position: absolute;
	top: 410%;
	left: 0;
	width: 300px;
	background: #ffffff;
	list-style: none;
	border-top: 3px solid #2ba2e9;
	 visibility: hidden;
	 opacity: 0; 
    transition: .4s;
    transform-origin: top center;
}

.navbar ul li:hover ul {
  visibility: visible;
  opacity: 1;
  top: 150%;
  padding-left: 0;
  left: 135px;
  box-shadow: 7px 13px 38px #8080801a;
    
}

.navbar ul li ul li {
	padding-left: 0;
	padding-right: 0;
	
}


.navbar ul li ul li a {
	display: block;
  color: #000000 ;
	padding: 14px ;
    transition: .4s;
    position: relative;
    padding-left: 30px;
    transition: .4s;
}


.navbar ul li ul li a::before{
 content: "-";
 font-size: 30px;
 position: absolute;
 top: 0;
 left:0;
}

.navbar ul li ul li a:hover{

    background:#4F87FB ;
    color:#F8F9FA;
    padding-left: 40px;
    width: 100%;

}
.navbar ul li ul li a::before {
	content: "-";
	font-size: 30px;
	position: absolute;
	top: 4px;
	left: 6px;
	height: 43px;
	margin-left: 4px;
	display: inline-block;
	visibility: hidden;
  
}
.navbar ul li ul li a:hover::before{visibility: visible;}

ul.edit {
  display: flex;
  flex-direction: column;
}


