.branch{
    background: url(../../image/fotor_2023-3-4_1_13_39.png)no-repeat ;
    background-size: contain;
    background-position: right;
    height: 100vh;
    margin-bottom: 100px;
}

.any-text {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.head-office {
    width: 100%;
}

.head-office h2 {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.head-office h5 {
    line-height: 1.7rem;
    font-size: 16px;
    font-weight: 600;
}