.Contact{background: url(../../image/20.png)no-repeat fixed;
height: 130vh;
background-size: contain;
background-position: left;
}

.single-input input {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #938f8f;
    color: black;
    font-family: 'Quicksand';
    border-radius: 6px;
    outline: none;
}
.double-input{
    display: flex;
    justify-content: space-between;
}
.double-input input {
    width: 48%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #938f8f;
    color: black;
    font-family: 'Quicksand';
    border-radius: 6px;
    outline: none;
    margin-bottom: 26px;
}
.Contact textarea{
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #938f8f;
    color: black;
    font-family: 'Quicksand';
    border-radius: 6px;
    outline: none;
    margin-bottom: 26px;
}
.Contact button{
    display: inline-block;
    padding: 15px 45px;
    text-transform: capitalize;
    font-weight: 900;
    position: relative;
    z-index: 1;
    color: #ffffff;
    background: #5a49f8;
    border: 2px solid #5a49f8;
    transition: all 0.35s ease-in-out;
    border-radius: 30px;
}


    
.address-section  {
    text-align: center;
} 
.address-section img {
    width: 100%;
    height: 100%;
    object-fit: contain;
} 


.team-section{background: url(../../image/svg4.svg)no-repeat;
    padding: 0px 0;
    background-size: contain;
    background-position: center;
    margin-bottom: 60px;
    }