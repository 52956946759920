.ComputerTrainingBanner{
    background: url(../../image/banner-bg-q2.png)no-repeat ;
    background-size: contain;
    background-position: right;
    height: 130vh;
}

.trainer-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 150px;
}