.Banner{
    background: url(../../image/23.png)no-repeat ;
    background-size: cover;
    background-position: center 100%;
}

.content {
    padding: 150px 0;
    color: #FFF;
}

.content h1 {
    font-size: 3.1rem;
    line-height: 4.1rem;
    font-weight: 900;
    padding-bottom: 10px;
}

.content p {
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
}

.content-img {
    text-align: center;
    margin-top: 100px;
}

.content-img img{
    width: 80%;
    height: 80%;
    object-fit: contain;
}
.content button{
   
        border: 2px solid rgba(255, 255, 255, 1) !important;
        display: inline-block;
        padding: 12px 30px;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        z-index: 1;
        color: #ffffff;
        background: none;
        &:hover{
            color: rgb(23, 0, 86);
            background: #FFF !important;
            transition: .3s ease-in-out;
        }
}

.primary-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    
}

.primary-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    transition: .3s ease-in-out;
    animation: img 3s ease-in infinite alternate;
}
