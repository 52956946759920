.meta-text-services h4 {
    font-size: 17px;
    font-weight: 600;
}

.meta-text-services h1 {
    font-size: 39px;
    font-weight: 700;
    padding: 30px 0;
    line-height: 64px;
}
.meta-text-services p {
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
    color: #555555;
} 
.meta-text-services h1 {
    font-size: 39px;
    font-weight: 700;
    padding: 14px 0;
    line-height: 56px;
}

.MetaService img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}
.service-description {
    text-align: center;
    padding: 8px;
    transition: 0.3s ease-in-out;
    border: 2px solid transparent;
    cursor: pointer;
}
.service-description:hover{
    border: 2px solid rgb(90, 73, 248);
    border-radius: 10px;
}
.service-description h2 {
    font-size: 20px;
    padding: 10px 0;
    font-weight: 700;
}

.service-description p {
    font-weight: 500;
    color: #767676;
}

.service-description.unique_class\} {
    margin: 0 23px;
    border: 2px solid rgb(90, 73, 248);
    padding: 16px;
    border-radius: 10px;
}

.MetaService{padding-bottom: 100px;
background: url(../../image/b-shape4-1.png) no-repeat;
background-size: contain;
background-position: left;
}

.service-description a {
    text-decoration: none;
    color: rgb(90, 73, 248);
    font-weight: 500;
}