.quaality-container {
    background: url(../../image/slider-1-1.jpg) no-repeat fixed top center;
    height: 150vh;
   background-size: cover;   
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.quality {
    text-align: center;
    color: #FFF;
}

.quality h1 {
    font-size: 74px;
    font-weight: 800;
    margin: 50px 0;
}


.quality p {
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
}

.quality button {
    padding: 16px 56px;
    background: none;
    border: 2px solid white;
    color: #FFF;
    font-weight: 600;
    transition: .3s ease-in-out;
    outline: none;
}
.quality button:hover{
    background: rgba(255, 255, 255, 0.334);
}