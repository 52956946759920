a.custom-link {
    color: black !important;
}

.main-bg {
    padding: 10px 30px;
    position: fixed;
    width: 100%;
}

.main-bg.change-web-bg{
    background: linear-gradient(45deg, #3DAEEC, #543AE5);
    transition: .3s ease-in-out
}

a.custom-link.need-color-change {
    color: #FFF ;
}

.web-image img {
    height: 100%;
    width: 100%;
}

.web-image{
    text-align: center !important;
}

.web-image img {
    position: relative;
    top: 100px;
}

.navbar.custom-static-menu.any-code ul li a.custom-link-need {
    color: black ;
}

a.random-ancor {
    color: black !important;
    &:hover{
        color: #FFF !important;
    }
}

a.custom-link.need-color-change {
    color: #FFF !important;
}