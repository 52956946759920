.ContactBanner{
    background: url(../../image/31.png)no-repeat ;
    background-size: contain;
    background-position: left;
    height: 100vh;
}

.contact__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.main__contact{
    width: 100%;
}

.main__contact h4 {
    font-size: 16px;
    color: #2784F7;
    font-weight: 700;
}
.main__contact h1 {
    font-size: 53px;
    font-weight: 700;
    padding: 15px 0;
}
.main__contact p {
    font-weight: 600;
    line-height: 26px;
}

p.contact-us-for-paragraph {
    font-weight: 800;
}