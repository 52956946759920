.About{
    background: url(../../image/40.png)no-repeat ;
    background-size: cover;
    padding: 20px 0;
    background-position: center 100%;
}
.about-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content a svg{
    font-size: 55px;
    color: aliceblue;
}
.content a {
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 56px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    right: 15px;
    transition: .3s ease-in-out;
}
.watch-video {
    display: flex;
    align-items: center;
}
.watch-video span {
    font-size: 15px;
    font-weight: 600;
    position: relative;
    right: 8px;
}


    .content a::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(209, 209, 209);
        animation:1s ease-in loading infinite;
        border-radius: 50%;
    }
    
    @keyframes loading{

    0%,25%{transform:scale(0);
    opacity: 1;}
    50%{transform:scale(1);
    opacity: .7;}
    100%{transform:scale(2);
    opacity: 0;}
    
    }