.team img {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    object-fit: cover;
    margin-top: 100px;
}

.meta-text-services h3 {
    font-size: 21px;
    padding: 15px 0;
    font-weight: 700;
    margin-bottom: 0;
}

 p.text-about {
    font-size: 15px;
    font-weight: 600;
}


.text-of-team {
    position: relative;
    bottom: 92px;
}
p.text-about.team-text-paragraph {
    color: rgb(252, 252, 252);
    text-align: center;
    width: 70%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
}

.text-of-team {
    position: relative;
    bottom: 145px;
    text-align: center;
}


.text-of-team h3 {
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0;
    margin-bottom: 0;
    color: rgb(252, 252, 252);

}

.meta-text-services.space-close {
    padding-top: 0;
}

.position {
    position: relative;
    bottom: 53px;
}

.team-center-img{
    background: url(../../image/21.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.team img {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    object-fit: cover;
    margin-top: 100px;
    background: #e1e1e1;
}


.team-social-icon {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.team-social-icon svg {
    color: #FFF;
}

.loader-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-transform: uppercase;
}

.loader-item h1 {
    font-size: 19px;
    font-weight: 600;
}

.loader-item h1 span{
    color: rgb(0, 108, 196);
}