@import "../../global.scss";

.menu {
  width: 100%;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active {
    right: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    width: auto;

    li {
      margin-bottom: 25px;
      a{
        font-size: 20px;
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-weight: 500;
    }

    //   &:hover {
        
    //   }
    }
  }
}