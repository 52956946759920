.custom-navbar {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
}

.phone.custom-navbar-phone a {
    background: none;
    padding: 15px 38px;
    color: #FFF !important;
    text-decoration: none;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    border: 2px solid #ffffff;
   
}

.navbar.custom-static-menu ul li a {
    color: #FFF;
    &:hover{
        color: #ffffff ;
    }
}

.navbar.custom-static-menu ul li a.active{
    color: #FFF ;
    text-decoration: underline;

}

.bg-change.custom-navbar.display-none-for-mobile-view {
    background: #2949D9;
    box-shadow: -1px 7px 19px #0000004c;
    transition: .5s ease-in-out;
    z-index: 990;
}
.services-card h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    padding-bottom: 8px !important;
    margin-bottom: 0;
    padding: 5px 0 !important;
}

