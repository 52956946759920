.ceo{
    background: url(../../image/apps2.png) no-repeat;
    background-size: contain;
    background-position: left;
    padding: 120px 0;
    }

    .meta-text-services.space-close.new-text {
        text-align: left;
    }
    span.new-position-for-new-text {
        position: relative;
        left: 295px;
    }

    .ceo-image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .meta-text-services.space-close.new-text.new-section {
        padding-bottom: 0;
    }

    h1.close-space {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .text-for-ceo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
    }

    .ceo-image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    .text-for-ceo p {
        font-weight: 500;
    }

    .text-for-ceo h2 {
        width: 100%;
        padding-bottom: 10px;
    }
    .text-for-ceo h5 {
        width: 100%;
        text-align: end;
        color: #5a49f8;
        font-size: 18px;
        font-weight: 600;
    }
    .text-for-ceo h2 {
        font-size: 24px;
        font-weight: 600;
    }

    .social-icon-for-ceo {
    width: 100%;
    display: flex;
    text-transform: capitalize;
    align-items: center;
}
.specifiq-span {
    width: 80px;
    height: 1px;
    background: black;
    font-weight: 600;
}

.icon-customize a {
    color: black;
    display: inline-block;
    margin-left: 25px;
}

.social-icon-for-ceo p {
    font-weight: 600;
    position: relative;
    top: 6px;
    margin-right: 22px;
    &:hover{
        position: relative;
        bottom: 2px;
        transition: .3s ease-out;
    }
}

.icon-customize a {
    color: black;
    display: inline-block;
    margin-left: 25px;
    &:hover{
        position: relative;
        bottom: 3px;
        transition: .3s ease-out;
        color: #5a49f8;
    }
}