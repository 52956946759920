
.team-section-{
    background: url(../../image/banner-bg-7.png)no-repeat;
    background-size: cover;
    height: 130vh;
    
}

.team-content-img {
    position: relative;
    top: 18%;
}

.team-content-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 50px;
}