.instructor-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.instructor-image.new-images img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
}

.instructor-image.new-images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}