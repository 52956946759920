.footer{
    background: url(../../image/footer.png)no-repeat;
    background-size: cover;
    padding: 150px 0;
}

.main-footer{
    background: #4628BA;
    padding: 40px 0 ;
    color: #FFF;
}

.main-footer h4 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 12px;
}

.main-footer p {
    font-size: 14px;
    font-weight: 500;
}
.main-footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.main-footer ul li a {
    color: #FFF;
    display: inline-block;
    margin-right: 21px;
}

.services ul {
    display: flex;
    flex-direction: column;
}
.services ul li a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    transition: .3s ease-in-out;
}

.services ul li a:hover{
    text-decoration: underline;
}

.services.need-space {
    margin-left: 40%;
}
.services.need-space h5 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 2px;
}

.all-right-reserved {
    text-align: center;
    padding-top: 12px;
    margin-top: 20px;
    border-top: 1px solid #ffffff70;
}
.all-right-reserved p {
    position: relative;
    top: 20px;
}
.all-right-reserved p a{
    color: white
}
.phone a {
    background: none;
    padding: 15px 38px;
    color: #FFF;
    text-decoration: none;
    font-weight: 600;
    border-radius: 50px;
    border: 2px solid #ffffff;
    &:hover{
        color: #ffffffcf !important;
        transition: .3s ease-in-out;
        border: 2px solid #ffffffbb;

    }
}
.change-background {
    background: white;
    box-shadow: 10px 10px 10px #80808017;
}

a.active {
    color: #5a49f8
}

a.our-link {
    color: black !important;
    &:hover{
        color: #FFF !important;
    }
}