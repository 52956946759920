.video-section {
    color: #fafafa;
    min-height: 100vh;
    padding: 100px 0;
    position: relative;
    width: 100%;
    z-index: 2;
}

.video-section video {
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: .8;
    position: absolute;
    top: 0;
    width: 100%;
}
.video-overly {
    background: rgba(0,0,0,.5);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}

.text-of-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    z-index: 9;
}
.quality h2 {
    font-size: 40px;
    padding: 13px 0;
    line-height: 60px;
    font-weight: 800;
}

.quality h1 {
    font-size: 66px !important;
    font-weight: 800;
    margin: 30px 0 !important;
}