.Courses_Service{
    padding: 120px 0;
}

.services-card img {
    width: 400px;
    height: 400px;
    object-fit: contain;
}
.services-card h3 {
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.services-card h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.services-card p {
    font-weight: 500;
    color: #767676;
    font-size: 15px;
    line-height: 23px;
}

.services-card {
    padding: 20px;
}

.services-card button {
    margin-top: 20px;
    padding: 14px 30px;
    border: 2px solid #5a49f8;
    background: #5a49f8;
    color: #FFF;
    font-weight: 500;
    border-radius: 38px;
    transition: .3s ease-in-out;
}

.services-card button:hover{
    background: none;
    color: #5a49f8;
}

.main-courses-image{
    background: url(../../image/apps2.png) no-repeat;
    background-size: contain;
    background-position:left;
    margin-bottom: 100px;
}
.main-courses-image-2{
    background: url(../../image/apps3.png) no-repeat;
    background-size: contain;
    background-position:right;
    margin-bottom: 100px;
}

.services-card h3 {
    font-weight: 700;
    padding-bottom: 8px !important;
    margin-bottom: 0;
}

.services-card h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    padding-bottom: 8px !important;
    margin-bottom: 0;
}
// .services-card button {
//     padding-left: 26px !important;
//     margin-top: 2px;
//     padding: 12px 18px !important;
//     border: 2px solid #5a49f8;
//     background: #5a49f8;
//     color: #FFF;
//     font-weight: 700 !important;
//     border-radius: 40px !important;
//     transition: 0.3s ease-in-out;
//     font-size: 15px !important;
// }

.services-card button {
    padding-left: 0 !important;
    margin-top: 2px;
    padding: 0;
    border: none;
    background: none;
    color: #5a49f8;
    font-weight: 700 !important;
    border-radius: 40px !important;
    transition: 0.3s ease-in-out;
    font-size: 17px !important;
}

.services-card h3 {
    font-weight: 600 !important;
    padding-bottom: 8px !important;
    margin-bottom: 0;
}