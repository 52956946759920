.content-of-digital-marketing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.content-of-digital-marketing h6 {
    font-size: 19px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 20px;
}
.instructor-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content-of-digital-marketing h6 svg {
    font-size: 36px;
    color: #39a4ff;
}

.list {
    width: 100%;
}

.list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.list ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.list ul li img {
    height: 20px;
    width: 20px;
    object-fit: cover;
    margin-right: 10px;
}

.list ul li span {
    font-weight: 600;
}

.Digital_module {
    background: url(../../image/apps3.png) no-repeat;
    background-size: contain;
    background-position: left;
}
.one-side-image {
    background: url(../../image/apps3.png) no-repeat;
    background-size: contain;
    background-position: right;
}

.meta-text-services.text-left {
    text-align: left;
}
.content-of-module h2 {
    margin-top: 24px;
    font-weight: 700;
    line-height: 47px;
}

.module-accordian {
    height: 65vh;
    margin-top: 30px;
    overflow: auto;
    border: 1px solid #80808063;
    border-radius: 10px;
}

.accordion-item {
    border-left: none;
    border-right: none;
    border-top: none;
}

.accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none;
}

h3.basic-tech {
    font-size: 18px;
    font-weight: 700;
}
.accordion-body p {
    color: black;
    font-weight: 500;
}